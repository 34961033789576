/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Import React
// const React = require( `react` )

// Import Styles
require( `styles/site.scss` )

// Import Context
// const { MediaContextProvider } = require( `stores/responsiveContext` )

// // Wrap our Root element with our Modal Provider to allow passing context up and down the chain
// exports.wrapRootElement = ({ element }) => {

//   return (
//     <MediaContextProvider>
//       { element }
//     </MediaContextProvider>
//   )
// }
